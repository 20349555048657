
@tailwind base;

@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}


html {
    font-size: 10px;
}

body {
    font-family: "Inter", sans-serif;
    background-color: #FAFAFA;
}

/*  .App {
    width: 100vh;
    height: 100vh;
}
 
.curseur-perso {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid #333;
    position: absolute;
    pointer-events: none;
}

.curseur-perso::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: crimson;    
} */

@layer base {

    label {
        @apply text-[16px] font-semibold;
    }

    button {
        @apply text-[20px] ;
    }

    h1 {
      @apply text-[48px] font-semibold ;
      
    }
    h2 {
      @apply text-[36px] font-semibold ;
    }
    h3 {
      @apply text-[24px] font-normal ;
    }
    h4 {
      @apply text-[20px] font-normal ;
    }
    p {
        @apply text-[16px] font-normal ;
      }
  }


@media only screen and (min-width : 2200px) {
    
    html {
        font-size: 10px;
    }
    }


    @media only screen and (max-width : 1440px) {
    
        html {
            font-size: 10px;
        }
        }

    @media only screen and (max-width : 1024px) {
    
        html {
            font-size: 7px;
        }

        h1 {
            font-size: 32px;
        }

        h2 {
            font-size: 26px;
          }
          h3 {
            font-size: 20px;
          }
          h4 {
            font-size: 18px ;
          }
          p {
              font-size: 16px ;
            }
        
        label {
                font-size: 16px ;
            }
        
        

        

        }

        @media only screen and (max-width : 768px) {
    
            html {
                font-size: 5px;
            }

            button {
                font-size: 16px ;
            }

            }

            @media only screen and (max-width : 425px) {
    
                html {
                    font-size: 3.5px;
                }
                }