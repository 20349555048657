/* .custom-cursor {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: black;
    opacity: 0.5;
    position: absolute;
    z-index: 9999;
    pointer-events: none;
    transform: translate(-50%, -50%);
    mix-blend-mode: difference;
  }

  .test {
    background-color: red;
  } */


  .custom-cursor {
    position: fixed;
    z-index: 9999;
    width: 40px;
    height: 40px;
    border: 2px solid #000;
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    mix-blend-mode: difference;
  }