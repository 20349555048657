header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: white;
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

@media only screen and (max-width: 768px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
		font-size: 20px;
	}

	header nav {
		position: fixed;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 1.5rem;
		background-color: #FE856E;
		transition: 1s;
		transform: translateY(-100vh);
	}

	header .responsive_nav {
		transform: none;
	}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

    .nav-open-btn {
        margin-right: 2rem;
    }
	
}